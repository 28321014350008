import { openNotification } from 'common/utils'
import history from '../../history'
import { GMT_LOGOUT_URL } from 'common/constants'
import firebase from "firebase";
export function authHeader() {
  let token = JSON.parse(localStorage.getItem('user'))
  if (token) {
    return 'Basic ' + token
  }
  return {}
}

export function isAuthenticated() {
  return localStorage.getItem('token')
}

export function authLogin(response) {
  console.log(response)
  localStorage.setItem('token', JSON.stringify(response.data.login))
  history.push('/', { isInit: true })
}

export function authLogout() {
  let isFirebase = process.env.REACT_APP_LOGIN_METHOD === "firebase" ? true : false
  if (isFirebase) {
    firebase.auth().signOut()
    indexedDB.deleteDatabase('firebaseLocalStorageDb');
  }
  localStorage.removeItem('token')
  localStorage.removeItem('userData')
  localStorage.removeItem('analyticsData')
  localStorage.clear();
  sessionStorage.clear();
  openNotification('success', 'Logout Successfully')
  window.location.href = isFirebase ? "/login" : GMT_LOGOUT_URL
}
