import gql from 'graphql-tag'

export const LOGIN = gql`
  mutation login($phone_no: String!) {
    login(phone_no: $phone_no)
  }`

export const TEMP_LOGIN=gql`
mutation mobileLogin($token: String!){
  mobileLogin(token: $token){
      token      
      euid
      user {
        id
        first_name
        last_name
        email
        phone_no
        roles
        retail_location {
          id
          description
          store_num
          clusters {
            id
            name
          }
        }       
        district 
        notification_config
      }
      client {
        id
        name
        description
        logos
      }
      is_signup,      
    }
}
`

export const VERIFY_CODE = gql`
  mutation verifyCode($code: String!) {
    verifyCode(code: $code){
      token      
      euid
      user {
        id
        first_name
        last_name
        email
        phone_no
        roles
        retail_location {
          id
          description
          store_num
          clusters {
            id
            name
          }
        }       
        district 
        notification_config
      }
      client {
        id
        name
        description
        logos
      }
      is_signup,      
    }
  }`

export const VERIFY_CODE_DUMMY_LOGIN = gql`
  mutation dummyLogin($code: String!) {
    dummyLogin(code: $code){
      token      
      euid
      user {
        id
        first_name
        last_name
        email
        phone_no
        roles
        retail_location {
          id
          description
          store_num
          clusters {
            id
            name
          }
        }       
        district 
        notification_config
      }
      client {
        id
        name
        description
        logos
      }
      is_signup,      
    }
  }`