import React from 'react'
import { Row } from 'antd'
import { NavLink } from 'react-router-dom'

const Link = ({ to, title, icon, count }) => <NavLink to={to} className='footer-button'>
  {!!(count && count > 0) && <div className='count'>{count}</div>}
  <Row justify='center' className='icon'>
    <i className={icon} />
  </Row>
  <Row justify='center' className='title'>
    {title}
  </Row>
</NavLink>

export default Link