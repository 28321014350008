import React from 'react'
import { Spin } from 'antd'

export default function () {
  return (
    <div className='callback-wrapper'>
      <Spin />
    </div>
  )
}
