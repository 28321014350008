import React, { Fragment, useContext, useEffect } from 'react'
import { AppContext } from 'AppContext'
import { authLogout } from 'modules/auth'

export default function () {
  const { dispatch } = useContext(AppContext)
  
  useEffect(() => {    
    dispatch({ type: 'CURRENT_USER', data: {} })
    authLogout()   
  }, [])

  return <Fragment />
}
