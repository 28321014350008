import React, { useEffect } from 'react'
import PageWrapper from 'components/page-wrapper'
import LoginModal from './components/LoginModal'
import { trackEvent } from '../../FirebaseConfig'

const EmployeeLogin = () => {
  useEffect(() => {
    trackEvent('login_page_view')
  }, [])
  return <PageWrapper>
    <LoginModal />
  </PageWrapper>
}

export default EmployeeLogin