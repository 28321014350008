import i18n from "i18next";

import { initReactI18next } from "react-i18next";
import resourcesToBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const i18 = i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(resourcesToBackend((language, namespace, callback) => {
        import(`./assets/locales/${language}/${namespace}.json`)
            .then((resources) => {
                callback(null, resources)
            })
            .catch((error) => {
                callback(error, null)
            })
    }))
    .init({
        fallbackLng: "en",
        supportedLngs: ["en","es"],
        react: {
          wait: true
      }
    })