import React, { Suspense, lazy } from 'react'
import {
  Route,
  Switch,
  useLocation,
  Redirect
} from 'react-router-dom'
import { Layout, Spin } from 'antd'
import { useContext, useEffect } from 'react'
import { AppContext } from 'AppContext'
import { startCase, lowerCase } from 'lodash'
import { trackEvent, getAnalyticsData } from './FirebaseConfig'
import { ROUTES } from 'common/constants'
import NavFooter from 'components/footer'
import './App.less'

const {
  NOTIFICATIONS,
  // EDIT_RADIUS,
  PROFILE,
  ADD_SHIFT,
  CALENDAR,
} = ROUTES

const Notifications = lazy(() => import('./modules/notifications'))
const Profile = lazy(() => import('./modules/profile'))
const Shifts = lazy(() => import('./modules/shifts/Shifts'))
const AddShift = lazy(() => import('./modules/shifts/AddShift'))
// const EditRadius = lazy(() => import('./modules/employee-login/EditRadius'))
const Page404 = lazy(() => import('./modules/auth/Page404'))
const { Footer, Content } = Layout

const App = () => {
  const location = useLocation()
  const {
    state: {
      // isMember, 
      isManager
    }
  } = useContext(AppContext)

  useEffect(() => {
    // runs on location, i.e. route, change
    const analyticsData = getAnalyticsData()    
    let pathName = lowerCase(location.pathname.substr(1))        
    if (pathName === lowerCase(CALENDAR)) {      
      pathName = 'shift'      
    }
    trackEvent(`${pathName}_page_view`, analyticsData)
  }, [location && location.pathname])

  return (
    <Suspense fallback={<div className='col-center h-100'><Spin /></div>}>
      <Layout className='layout'>
        <Content className='only-h-100'>
          <Switch>
            <Route exact path='/' render={() => {
              // if (location.state && location.state.isInit && isMember) {
              //   return <Redirect to={EDIT_RADIUS} />
              // }
              return <Redirect to={CALENDAR} />
            }} />
            <Route
              exact
              path={NOTIFICATIONS}
              component={Notifications} />
            <Route
              exact
              path={CALENDAR}
              component={Shifts} />
            <Route
              exact
              path={PROFILE}
              component={Profile} />
            {/* {
              isMember && <Route
                exact
                path={EDIT_RADIUS}
                component={EditRadius} />
            } */}
            {
              isManager && <Route
                exact
                path={ADD_SHIFT}
                component={AddShift} />
            }
            <Route component={Page404} />
          </Switch>
        </Content>
        <Footer className="footer-container">
          <NavFooter />
        </Footer>
      </Layout>
    </Suspense>
  )
}

export default App