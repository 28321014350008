import React from 'react'
import { Col } from 'antd'

export default ({
  children,
  className,
  ...props
}) => {
  return <Col
    className={`page-wrapper ${className}`}
    {...props}>
    {children}
  </Col>
}