
import * as firebase from "firebase/app"
import "firebase/analytics"

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const initFirebaseConfig = () => {
  firebase.initializeApp(firebaseConfig)
}

export const firebaseAnalytics = () => firebase.analytics()

export const trackEvent = (event, params) => {
  try {
    if (params) {
      firebase.analytics().logEvent(event, params)
    } else {
      firebase.analytics().logEvent(event)
    }
  } catch (error) {
    console.log('Error log event event: ', event, 'params: ', params)
  }
}

export const getAnalyticsData = () => {
  return JSON.parse(localStorage.getItem('analyticsData')) || {}
}
