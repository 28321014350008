import React, { useState, useEffect } from 'react'
import { Modal, Form, Row, Button, Spin } from 'antd'
import { GMT_LOGIN_URL } from '../../../common/constants'
import logo from '../../../assets/logo.png'
import { trackEvent, getAnalyticsData, initFirebaseConfig, firebaseConfig } from '../../../FirebaseConfig'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from "firebase";
import FireBaseLogin from './FireBaseLogin'
const LoginModal = (props) => {
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const [tempLogin, setTempLogin] = useState(false)

  const loginWithData = (values) => {
    setLoading(true)
    let isFirebase = process.env.REACT_APP_LOGIN_METHOD === "firebase" ? true : false

    if (isFirebase) {
      setTempLogin(true)
      setLoading(false)
    } else {
      // const redirectUrl = process.env.REACT_APP_GMT_LOGIN_URL
      console.log('GMT_LOGIN_URL: ', GMT_LOGIN_URL)
      console.log('process.env.REACT_APP_GMT_LOGIN_URL: ', process.env.REACT_APP_GMT_LOGIN_URL)

      const dummyPromise = new Promise((resolutionFunc, rejectionFunc) => {
        setTimeout(() => resolutionFunc(GMT_LOGIN_URL), 1000)
      })
      dummyPromise.then((val) => {
        setLoading(false)
        window.location.href = val
      })
    }
  }

  return <Modal
    className='log-in-modal'
    centered
    visible
    closable={false}
    maskClosable={false}
    footer={null}
    cancelText={() => null}>
    <Spin spinning={loading}>
      <Row justify='center'>
        <img src={logo} height='70px' />
      </Row>

      <Row justify='center'>
        {tempLogin ? <FireBaseLogin /> :
          <Form
            className='w-100'
            layout='verticle'
            onFinish={loginWithData}
            form={form}>
            <Row justify='center' className='p-2'>
              <Button
                loading={loading}
                type='primary'
                className='w-100'
                htmlType='submit'
                size='large'>
                Login to GotSHIFT
          </Button>

            </Row>
          </Form>
        }</Row>
    </Spin>
  </Modal>
}

export default LoginModal
