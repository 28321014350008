import gql from 'graphql-tag'

export const GET_CURRENT_USER = gql`
query currentUser {
  currentUser {
    id
    position_ids
    phone_no
    retail_location {
      id
      description
      store_num
      clusters {
        id
        name
      }
    }
    area
    radius
    first_name
    last_name
    mute_notification
    positions{
      id
      name
    }
    roles
    district
    notification_config
  }
}
`

export const GET_UI_LABELS = gql`
query uiLabels {
  uiLabels{
    id
    key
    value
    language
  }
}
`