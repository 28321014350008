import { createUploadLink } from 'apollo-upload-client'
import { ApolloLink, from, split } from 'apollo-link'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { getMainDefinition } from 'apollo-utilities'
import { openNotification } from 'common/utils'
import { createHttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { ErrorLink } from 'apollo-link-error'
import { ApolloClient } from 'apollo-client'
import { version } from '../package.json'
import { isAuthenticated } from './modules/auth'
import history from './history'
import * as Sentry from '@sentry/react';
import { DEFAULT_LANGUAGE } from 'common/constants'

export const cacheData = new InMemoryCache()

function stripTypeNames(obj, propToDelete) {
  for (const property in obj) {
    if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
      delete obj.property
      const newData = stripTypeNames(obj[property], propToDelete)
      obj[property] = newData
    } else {
      if (property === propToDelete) {
        delete obj[property]
      }
    }
  }
  return obj
}

const removeTypenameMiddleware = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = stripTypeNames(operation.variables, '__typename')
  }
  return forward ? forward(operation) : null
})

const errorLink = new ErrorLink(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors) {
    console.log('graphQLErrors: ', graphQLErrors)
    graphQLErrors.map(({ extensions, message, locations, path }) => {
      console.log('path: ', path)
      if (Array.isArray(path) && path[0] === 'currentUser') {
        history.push('/logout')
      }
      if (
        extensions &&
        extensions.code &&
        extensions.code == 'UNAUTHENTICATED'
      ) {
        if (message == 'User not found!') {
          openNotification('error', message)
          indexedDB.deleteDatabase('firebaseLocalStorageDb')
          return
        }

        history.push('/logout')
      }
      if (message === 'Validation error') {
        openNotification(
          'error',
          'Phone no is already linked with other EUID, please contact Admin.'
        )
      } else {
        openNotification('error', message)
      }
      // Enable when sentry integrated
      Sentry.captureException(
        new Error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        ))
      return console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    })
  }

  if (response) {
    response.errors.map(({ message, locations, path }) => {
      // openNotification('error', message)
      // Enable when sentry integrated
      Sentry.captureException(
        new Error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        ))
      return console.log(
        `[Response error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    })
  }

  if (networkError) {
    // Enable when sentry integrated
    Sentry.captureException(
      new Error(
        `[Network error]: ${networkError}`
      ))
    // openNotification('error', networkError)
    console.log(`[Network error]: ${networkError}`)
  }
})

const httpLink = createHttpLink({
  // credentials: 'include',
  uri: process.env.REACT_APP_SERVER_GRAPH_URL,
})

const authMiddleware = new ApolloLink((operation, forward) => {
  const authorizationToken = isAuthenticated()
  const language = localStorage.getItem('i18nextLng')
  
  operation.setContext({
    headers: {
      'access-control-allow-origin': '*',
      authorization: authorizationToken ? `Bearer ${authorizationToken}` : '',
      'x-display-msg-lang': language ? language?.toUpperCase() : DEFAULT_LANGUAGE
    },
  })

  return forward(operation)
})

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_SERVER_SOCKET_URL,
  options: {
    reconnect: true,
    timeout: 30000,
    lazy: true,
    async connectionParams() {
      const authorizationToken = isAuthenticated()
      return {
        authorization: authorizationToken ? `Bearer ${authorizationToken}` : '',
      }
    },
  },
})

const responseMessageLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const { data } = response

    if (
      data &&
      Object.keys(data).length > 0 &&
      data[`${Object.keys(data)[0]}`] &&
      data[`${Object.keys(data)[0]}`].message
    ) {
      // setTimeout(() => {
      const response = data[`${Object.keys(data)[0]}`]

      if (!response) {
        return response
      }
      openNotification(
        response.status === 'ERROR' ? 'error' : 'success',
        response.message || 'Operation successful'
      )
    }
    return response
  })
})

window.addEventListener('beforeunload', () => {
  // @ts-ignore - the function is private in typescript
  wsLink.subscriptionClient.close()
})

const uploadLink = createUploadLink({
  // credentials: 'include',
  uri: process.env.REACT_APP_SERVER_URL,
})

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

const client = new ApolloClient({
  cache: cacheData,
  link: from([
    removeTypenameMiddleware,
    responseMessageLink,
    errorLink,
    authMiddleware,
    uploadLink,
    link,
  ]),
  name: 'ADMIN',
  version,
})

export default client
