import React, { useState, useEffect } from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from 'firebase'
import { get } from 'lodash'
import { useMutation } from '@apollo/react-hooks'
import { TEMP_LOGIN } from '../../auth/graphql/Mutations'
import history from '../../../history'
import { Spin } from 'antd'
const FireBaseLogin = (props) => {
  const [setSignedIn, setIsSignedIn] = useState(false)

  const getClusterName = (clusters) => {
    const clusterArr = clusters.map((cluster) => cluster.name)
    return clusterArr.join(', ')
  }

  const setAnalyticsData = (verifyCodeObj) => {
    const analyticsData = {
      name: `${get(verifyCodeObj, 'user.first_name')} ${get(
        verifyCodeObj,
        'user.last_name'
      )}`,
      client: get(verifyCodeObj, 'client.name'),
      role: get(verifyCodeObj, 'user.roles')[0],
      store_id: get(verifyCodeObj, 'user.retail_location.store_num'),
      store_name: get(verifyCodeObj, 'user.retail_location.description'),
      district: get(verifyCodeObj, 'user.district'),
      cluster:
        get(verifyCodeObj, 'user.retail_location.clusters') !== null
          ? getClusterName(get(verifyCodeObj, 'user.retail_location.clusters'))
          : null,
    }
    localStorage.setItem('analyticsData', JSON.stringify(analyticsData))
  }

  const [mobileLogin, { loading }] = useMutation(TEMP_LOGIN)

  const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      {
        provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        defaultCountry: 'EN_USA',
      },
    ],
    callbacks: {
      signInSuccessWithAuthResult: (res) => false,
    },
  }

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          user.getIdToken().then(function (idToken) {
            getAuthenticate(idToken)
          })
        }
        setIsSignedIn(!!user)
      })
    return () => unregisterAuthObserver() // Make sure we un-register Firebase observers when the component unmounts.
  }, [])

  const getAuthenticate = (token) => {
    const variables = {
      token: token,
    }
    mobileLogin({ variables })
      .then((res) => {
        if (res && res.data && res.data.mobileLogin) {
          const verifyCodeObj = res.data.mobileLogin
          if (verifyCodeObj && verifyCodeObj.token) {
            localStorage.setItem('token', verifyCodeObj.token)
            localStorage.setItem('userData', JSON.stringify(verifyCodeObj))
            setAnalyticsData(verifyCodeObj)
            history.push('/', { isInit: true })
          }
        }
      })
      .catch((error) => {
        console.log('MobileLogin error: ', error)
        setTimeout(() => {}, 2000)
      })
  }

  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <StyledFirebaseAuth
          uiCallback={(ui) => ui.disableAutoSignIn()}
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
      )}
    </>
  )
}

export default FireBaseLogin
