import { notification } from 'antd';
import { SHIFT_COLORS } from './constants';
import { last, first } from 'lodash';
import moment from 'moment';

// returns array of seven days of moment object from today
export const sevenDaysFromDay = (day) => {
  const arr = [];
  for (let i = 0; i < 7; i++) {
    arr.push(day.startOf('week').clone().add(i, 'day'));
  }
  return arr;
};

export const openNotification = (type, message, description) => {
  if (typeof message !== 'string') {
    return;
  }
  notification[type]({
    duration: 2.5,
    message,
    description,
  });
};

export const getRandomColor = () =>
  SHIFT_COLORS[Math.floor((Math.random() * 10) % SHIFT_COLORS.length)];

export const combineDateTime = (date, time) =>
  date.set({
    hour: time.get('hour'),
    minute: time.get('minute'),
    second: 0,
    milisecond: 0,
  });

export const combineDateTimeFormat = (date, hour, min, timeStamp) => {
  let newDate = date.set({
    hour: hour,
    minute: min,
    second: 0,
    milisecond: 0,
  });
  return newDate;
};

export const toNumberString = (phone) => {
  const phoneString = phone.toString();
  return `(${phoneString.slice(0, 3)}) ${phoneString.slice(
    3,
    6
  )}-${phoneString.slice(6, 10)}`;
};

export const formatTime = (date) => {
  const newTime = moment(date).format('hh:mm a');
  return newTime;
};

export const getUiLabelValue = (data, key) => {
  const findValue = data.find(_ => _.key === key)
  return findValue?.value ?? ''
}

export const capitalizeInitialLetter = (string = '', isSplit = false, splitChar = '-', splitIndex = 0) => {
  if (string?.length > 0) {
    if (isSplit) {
      const temp = string?.replace(/ /g, '')?.split(splitChar)
      if (splitIndex !== 0) {
        return `${temp[0]} - ${temp[1]?.charAt(0)?.toUpperCase()}${temp[1]?.slice(1)}`
      }
      return `${temp[0]?.charAt(0)?.toUpperCase()}${temp[0]?.slice(1)}, ${temp[1]?.charAt(0)?.toUpperCase()}${temp[1]?.slice(1)?.replace(/\./g, ' ')}`
    }
    return `${string?.charAt(0)?.toUpperCase()}${string?.slice(1)}`
  }
  return string
}
