import React, { Component } from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { initFirebaseConfig, trackEvent } from './FirebaseConfig'
import GuardedRoute from 'modules/auth/GuardedRoute'
import Logout from 'modules/employee-login/Logout'
import Login from 'modules/employee-login'
import history from './history'
import Layout from './App'
import Authorize from './modules/authorize'
import { isAuthenticated } from './modules/auth'
import { ROUTES } from 'common/constants'

const { LOG_IN, LOGOUT: LOGOUT_ROUTE, LAYOUT, AUTHORIZE } = ROUTES

export default class Routes extends Component {
  constructor(props){
    super(props)   
    initFirebaseConfig()
  }

  componentDidMount(){
    trackEvent('init_app', { app_state: 'initiate' })
  }  

  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path={LOG_IN} render={(props) => {
            return isAuthenticated() ? <Redirect to='/' /> : <Login {...props} />
          }} />
          <Route exact path={LOGOUT_ROUTE} component={Logout} />
          <Route exact path={AUTHORIZE} component={Authorize} />
          <GuardedRoute path={LAYOUT} name='layout' component={Layout} />
        </Switch>
      </Router>
    )
  }
}
