import gql from 'graphql-tag';

export const GET_NOTIFICATIONS = gql`
  query notifications($skip: Int, $limit: Int, $offset: Int) {
    notifications(skip: $skip, limit: $limit, offset: $offset) {
      id
      title
      description
      type
      ref_data
      receiver_id
      display_date
    }
  }
`;
export const GET_UNREAD_COUNT = gql`
  query unreadCount {
    unreadCount
  }
`;
