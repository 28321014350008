import React, { useEffect, useState } from 'react'
import { Row, Col, Badge, Radio, Dropdown, Menu } from 'antd'
import { get } from 'lodash'
import { DEFAULT_LANGUAGE, ROUTES } from 'common/constants'
import { GET_UNREAD_COUNT } from 'modules/notifications/graphql/Queries'
import Link from './components/Link'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useTranslation } from "react-i18next";
import { UPDATE_USER } from 'modules/profile/graphql/Mutations'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import langIconActive from '../../assets/lang_icon_active.png'
import langIconInactive from '../../assets/lang_icon_inactive.png'

const { NOTIFICATIONS, PROFILE, CALENDAR } = ROUTES

const Footer = () => {
  const [dropDownVisible, setDropDownVisible] = useState(false)
  const {
    data: {
      unreadCount = 0
    } = {},
  } = useQuery(GET_UNREAD_COUNT, {
    fetchPolicy: 'network-only'
  })

  const [updateUser] = useMutation(UPDATE_USER)

  const userDataObj = JSON.parse(localStorage.getItem('userData'))
  const logoImgUrl = get(userDataObj, 'client.logos.en-US.Large')
  const defaultUrl = process.env.REACT_APP_GMT_LOGO_URL
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!process.env.REACT_APP_MULTI_LANG) {
      i18n.changeLanguage(DEFAULT_LANGUAGE.toLowerCase());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeLanguage = (val) => {
    i18n.changeLanguage(val);
    updateUser({
      variables: {
        user: {
          language: val?.toUpperCase()
        }
      }
    }).then(() =>
      window.location.reload()
    ).catch((error) => { return error })
  };

  const options = [
    { label: "English", value: "en" },
    { label: "Español", value: "es" },
  ];

  const menu = (
    <Menu theme='light' onClick={(e) => {
      changeLanguage(e.key)
      setDropDownVisible(false)
    }} selectedKeys={i18n.language}>
      {options.map(_ => (
        <Menu.Item key={_.value}>
          {_.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleVisibleChange = flag => {
    setDropDownVisible(flag);
  };


  return <Row align="middle">
    <Col flex="2" className="height-75">
      <img src={logoImgUrl ? logoImgUrl : defaultUrl}
        className="footer-logo-img" alt="logo" />
    </Col>
    <Col flex="3">
      <Row justify="space-around">
        <div className="footer-icons">
          <Link to={CALENDAR} icon='icon-store-notification' />
          <Link to={PROFILE} icon='icon-profile-header' />
          <Badge count={unreadCount}>
            <Link to={NOTIFICATIONS} icon='icon-bell-header' />
          </Badge>
        </div>
        {process.env.REACT_APP_MULTI_LANG &&
          <div className="lang-switch">
            <Dropdown trigger={['click']} placement="topLeft" onVisibleChange={handleVisibleChange}
              visible={dropDownVisible} overlay={menu}>
              <div>
                <img height={25} width={25} src={dropDownVisible ? langIconActive : langIconInactive} alt='Language-Icon' />
                {dropDownVisible ?
                  <DownOutlined style={{ marginLeft: '8px' }} />
                  :
                  <UpOutlined style={{ marginLeft: '8px', fontSize: '10px' }} />
                }
              </div>
            </Dropdown>
          </div>
        }
      </Row>
    </Col>
  </Row>
}

export default Footer